const slider = document.querySelector('.posts-slider');
const slides = document.querySelectorAll('.posts-slide');
const slidesWidth = [];
let widnowWidth = 0;
let autoSlide;

function getWindowWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;;
};

function calcSlidesWidth() {
  slidesWidth.length = 0;
  let width = 0;
  for(let i = 0; i < slides.length; i++) {
    width += slides[i].offsetWidth;
    slidesWidth.push(width);
  }
}

function calcSliderParams() {
  widnowWidth = getWindowWidth();
  calcSlidesWidth();
}

function refresh() {
  clearInterval(autoSlide);

  autoSlide = setInterval(function() {
    let scrolled = slider.scrollLeft;
    let counter = 0;

    for(let i = 0; i < slidesWidth.length; i++) {
      if((i === 0 && scrolled >= slidesWidth[i]*0.5) || (scrolled >= (slidesWidth[i] - slidesWidth[i-1])*0.5 + slidesWidth[i-1] )) {
        counter++;
      }
    }

    if(counter === slidesWidth.length - 1) {
      slider.scrollLeft = 0;
    } else {
      slider.scrollLeft = slidesWidth[counter];
    }
  }, 2000);
}


function slideIt() {
  calcSliderParams();
  if(widnowWidth < 960) {
    refresh();
  } else {
    clearInterval(autoSlide);
  }
}

slideIt();
window.addEventListener('resize', slideIt);

slider.addEventListener('mouseover', function() {
  clearInterval(autoSlide);
})

slider.addEventListener('mouseout', function() {
  refresh();
})
